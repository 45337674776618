<template>
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70317 7.69271L1.68338 13.7123C1.30045 14.0954 0.679593 14.0954 0.296848 13.7123C-0.0859285 13.3295 -0.0859285 12.7087 0.296848 12.326L5.62343 6.99953L0.297003 1.6733C-0.0857736 1.29037 -0.0857736 0.669607 0.297003 0.286832C0.679779 -0.0960988 1.30061 -0.0960988 1.68354 0.286832L7.70333 6.30652C7.89472 6.498 7.9903 6.74869 7.9903 6.9995C7.9903 7.25044 7.89453 7.50132 7.70317 7.69271Z"
      fill="#1F2020"
    />
  </svg>
</template>

<script>
export default {
  name: 'NavigateRightArrow'
}
</script>

<style scoped></style>
