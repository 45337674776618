<template>
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9218 10.8642C29.7254 10.2567 29.1866 9.82523 28.5492 9.76778L19.8903 8.98155L16.4664 0.967508C16.2139 0.380183 15.639 0 15.0002 0C14.3613 0 13.7864 0.380182 13.5339 0.968882L10.11 8.98155L1.44977 9.76778C0.813463 9.82661 0.276035 10.2567 0.0785047 10.8642C-0.119025 11.4716 0.0633981 12.1379 0.544749 12.5579L7.08979 18.298L5.15981 26.7995C5.01859 27.4246 5.26121 28.0708 5.77987 28.4457C6.05865 28.6471 6.38482 28.7497 6.71373 28.7497C6.99732 28.7497 7.27863 28.6732 7.53109 28.5222L15.0002 24.0582L22.4665 28.5222C23.0128 28.8508 23.7015 28.8209 24.2191 28.4457C24.7379 28.0696 24.9803 27.4233 24.8391 26.7995L22.9091 18.298L29.4542 12.5591C29.9355 12.1379 30.1193 11.4728 29.9218 10.8642Z"
      fill="var(--inner)"
    />
    <path
      d="M6.71463 28.7496C6.38595 28.7496 6.05955 28.6471 5.781 28.4457C5.26211 28.0696 5.01972 27.4232 5.16094 26.7995L7.09092 18.298L0.545655 12.5579C0.0645339 12.1379 -0.119263 11.4728 0.0782672 10.8641C0.275797 10.2567 0.813225 9.8266 1.44953 9.76777L10.1097 8.98154L13.5336 0.968881C13.7861 0.380182 14.3611 0 14.9999 0C15.6387 0 16.2137 0.380182 16.4662 0.967507L19.8901 8.98154L28.5489 9.76777C29.1866 9.82522 29.724 10.2567 29.9215 10.8641C30.1191 11.4716 29.9364 12.1379 29.4553 12.5579L22.91 18.2968L24.84 26.7981C24.9815 27.4232 24.7388 28.0696 24.2202 28.4445C23.7027 28.8195 23.0126 28.8483 22.4676 28.5208L14.9999 24.0581L7.53222 28.5233C7.27975 28.6732 6.99845 28.7496 6.71463 28.7496ZM14.9999 22.1357C15.2837 22.1357 15.5648 22.2119 15.8175 22.3618L22.8652 26.577L21.0437 18.5529C20.9137 17.9819 21.1075 17.3856 21.5488 16.9992L27.729 11.5792L19.5525 10.8367C18.9638 10.7829 18.4575 10.4128 18.2274 9.87031L14.9999 2.30879L11.7685 9.87146C11.541 10.4103 11.0347 10.7804 10.4473 10.8342L2.26964 11.5767L8.4496 16.9967C8.89227 17.3842 9.0859 17.9793 8.95475 18.5518L7.13464 26.5757L14.1823 22.3618C14.4348 22.2119 14.7161 22.1357 14.9999 22.1357ZM10.0436 9.1365C10.0436 9.1365 10.0436 9.13787 10.0422 9.13902L10.0436 9.1365ZM19.9537 9.13284L19.9551 9.13536C19.9551 9.13398 19.9551 9.13398 19.9537 9.13284Z"
      fill="var(--outer)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Star'
}
</script>

<style scoped></style>
