<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.8014 0C3.04466 0 0.000664315 2.49375 0.000664315 5.57143C0.000664315 6.9008 0.567895 8.11554 1.51425 9.07232C1.18215 10.4215 0.0723984 11.6242 0.0591143 11.6376C-0.000664093 11.6979 -0.0172692 11.7912 0.0192621 11.8715C0.0525201 11.9518 0.126141 12 0.212487 12C1.97263 12 3.27049 11.1493 3.94798 10.6235C4.8141 10.9527 5.78118 11.1429 6.8014 11.1429C10.5581 11.1429 13.5789 8.6483 13.5789 5.57143C13.5789 2.49455 10.5581 0 6.8014 0Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'MessagesIcon'
}
</script>

<style lang="scss" scoped></style>
