<template>
  <div>
     <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9" fill="none">
<path d="M1.11967 4.05445L4.98953 0.184716C5.2357 -0.061573 5.63483 -0.061573 5.88088 0.184716C6.12695 0.430786 6.12695 0.829888 5.88088 1.07594L2.45664 4.50006L5.88078 7.92407C6.12685 8.17024 6.12685 8.5693 5.88078 8.81537C5.63471 9.06154 5.2356 9.06154 4.98943 8.81537L1.11957 4.94558C0.996533 4.82248 0.935085 4.66132 0.935085 4.50008C0.935085 4.33877 0.996653 4.17749 1.11967 4.05445Z" fill="#1F2020"/>
</svg>
      </div>
</template>

<script>
export default {
name: 'LeftArrow'
}
</script>

<style>

</style>