<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="706" height="370" viewBox="0 0 706 370" fill="none">
<path d="M285.635 126.969C285.636 114.304 289.208 101.895 295.943 91.1687C302.677 80.4421 312.299 71.8322 323.706 66.3274C335.112 60.8227 347.84 58.6463 360.427 60.0483C373.014 61.4502 384.951 66.3737 394.867 74.2532C404.782 82.1327 412.275 92.649 416.484 104.594C420.693 116.54 421.448 129.43 418.662 141.785C415.876 154.14 409.662 165.459 400.734 174.442C391.805 183.425 380.525 189.708 368.187 192.569H463.97C475.673 172.764 481.657 150.103 481.256 127.102C481.256 91.3729 468.908 61.242 444.212 36.7095C419.19 12.2365 388.74 0 352.862 0C316.984 0 286.69 12.1845 261.979 36.5536C249.961 47.9419 240.441 61.7031 234.022 76.9645C227.603 92.226 224.425 108.655 224.69 125.209C224.69 150.677 230.497 173.16 242.109 192.658H337.759C322.925 189.214 309.695 180.844 300.229 168.914C290.762 156.984 285.618 142.198 285.635 126.969V126.969Z" fill="white"/>
<path d="M239.949 308.667V289.555H223.554V272.403H202.348V289.555H185.53V308.667H202.348V349.364H223.554V308.667H239.949Z" fill="white"/>
<path d="M21.7629 328.225L38.8702 349.364H65.5114L39.3603 317.199L84.3117 273.116H56.6459L21.8297 307.932V273.116H0V349.364H21.7852L21.7629 328.225Z" fill="white"/>
<path d="M161.696 301.116C157.937 297.36 153.458 294.401 148.528 292.418C143.598 290.436 138.318 289.469 133.005 289.578C127.716 289.47 122.46 290.433 117.553 292.408C112.646 294.383 108.188 297.33 104.448 301.072C100.68 304.647 97.6946 308.966 95.6816 313.755C93.6686 318.544 92.6716 323.699 92.7538 328.893C92.6302 336.056 94.3974 343.125 97.8771 349.387H125.966C121.261 347.721 117.296 344.446 114.772 340.14C112.247 335.834 111.326 330.775 112.171 325.856C113.016 320.936 115.573 316.474 119.389 313.258C123.206 310.041 128.036 308.277 133.027 308.277C138.019 308.277 142.849 310.041 146.666 313.258C150.482 316.474 153.039 320.936 153.883 325.856C154.728 330.775 153.807 335.834 151.283 340.14C148.759 344.446 144.794 347.721 140.089 349.387H168.267C171.682 343.328 173.427 336.471 173.323 329.517C173.437 324.239 172.463 318.994 170.462 314.108C168.462 309.222 165.478 304.8 161.696 301.116Z" fill="white"/>
<path d="M355.022 323.792V369.412H375.27V324.171C375.068 319.912 375.957 315.672 377.854 311.853H356.225C355.383 315.776 354.979 319.78 355.022 323.792V323.792Z" fill="white"/>
<path d="M422.984 308.133C421.358 304.007 418.723 300.354 415.322 297.507C409.419 292.384 399.128 289.823 390.084 289.823C386.413 289.829 382.753 290.232 379.169 291.025V309.892C380.527 308.365 382.216 307.169 384.109 306.396C386.001 305.624 388.045 305.296 390.084 305.437C399.974 305.437 404.912 311.689 404.897 324.193V369.434H425.167V323.792C425.412 318.482 424.672 313.173 422.984 308.133V308.133Z" fill="white"/>
<path d="M315.684 329.472C315.695 333.138 314.753 336.742 312.952 339.934C311.151 343.126 308.551 345.795 305.408 347.681C302.265 349.566 298.686 350.602 295.022 350.689C291.358 350.775 287.735 349.909 284.506 348.174C281.278 346.439 278.555 343.895 276.605 340.792C274.655 337.689 273.545 334.132 273.382 330.471C273.22 326.81 274.011 323.169 275.679 319.905C277.346 316.641 279.833 313.867 282.895 311.853H258.081C255.51 317.155 254.199 322.979 254.249 328.871C254.078 334.226 255.007 339.559 256.98 344.54C258.954 349.521 261.929 354.044 265.721 357.829C269.407 361.626 273.84 364.619 278.739 366.62C283.638 368.621 288.898 369.587 294.189 369.456C299.532 369.581 304.846 368.637 309.819 366.678C314.792 364.72 319.324 361.787 323.147 358.052C326.966 354.378 329.98 349.95 331.998 345.049C334.015 340.149 334.991 334.882 334.863 329.584C334.931 323.48 333.59 317.443 330.943 311.942H306.217C309.118 313.858 311.499 316.461 313.151 319.519C314.803 322.577 315.673 325.997 315.684 329.472V329.472Z" fill="white"/>
<path d="M616.333 308.133C614.706 304.001 612.062 300.346 608.648 297.507C602.767 292.384 592.476 289.823 583.432 289.823C574.388 289.823 564.008 292.384 558.128 297.507C554.763 300.359 552.174 304.014 550.599 308.133C549.015 313.175 548.263 318.441 548.371 323.725V349.052H568.641V324.171C568.641 311.652 573.564 306.351 583.454 306.351C592.119 306.351 596.976 310.249 598.045 319.916H618.427C618.375 315.901 617.667 311.92 616.333 308.133V308.133Z" fill="white"/>
<path d="M465.552 329.472C465.541 325.629 466.578 321.856 468.551 318.558C470.525 315.261 473.359 312.563 476.751 310.756C480.143 308.949 483.963 308.101 487.801 308.302C491.638 308.504 495.348 309.748 498.532 311.901C501.716 314.053 504.252 317.033 505.869 320.519C507.486 324.006 508.121 327.867 507.708 331.688C507.294 335.508 505.847 339.144 503.521 342.204C501.196 345.263 498.081 347.631 494.51 349.052H526.23V289.734H505.893V294.857C499.717 291.328 492.713 289.506 485.6 289.578C480.311 289.47 475.055 290.433 470.148 292.408C465.24 294.383 460.783 297.33 457.043 301.072C453.275 304.647 450.289 308.966 448.276 313.755C446.263 318.544 445.266 323.699 445.349 328.893C445.231 335.931 446.927 342.881 450.271 349.075H478.917C474.983 347.522 471.605 344.823 469.222 341.328C466.839 337.833 465.56 333.703 465.552 329.472V329.472Z" fill="white"/>
<path d="M685.697 335.042C685.697 347.56 680.76 353.82 670.884 353.82C661.009 353.82 656.071 347.56 656.071 335.042V309.113H635.801V335.443C635.58 340.733 636.335 346.018 638.028 351.035C639.668 355.16 642.309 358.812 645.713 361.661C651.594 366.784 661.885 369.345 670.929 369.345C679.973 369.345 690.331 366.784 696.234 361.661C699.588 358.801 702.176 355.148 703.763 351.035C705.344 345.992 706.096 340.726 705.99 335.443V309.113H685.72L685.697 335.042Z" fill="white"/>
</svg>
</template>

<script>
export default {
name: 'Logo'
}
</script>

<style>

</style>