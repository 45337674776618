export const CHATS_REQUEST = 'CHATS_REQUEST'

export const CHATS_GET_ALL = 'CHATS_GET_ALL'
export const CHATS_GET_ALL_SUCCESS = 'CHATS_GET_ALL_SUCCESS'

export const CHATS_GET = 'CHATS_GET'
export const CHATS_GET_SUCCESS = 'CHATS_GET_SUCCESS'

export const CHATS_SAVE_FAVORITE = 'CHATS_SAVE_FAVORITE'
export const CHATS_SAVE_FAVORITE_SUCCESS = 'CHATS_SAVE_FAVORITE_SUCCESS'
