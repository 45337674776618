<template>
  <div class="full-width">
    <v-row
      no-gutters
      v-if="items"
      class="fw-500
          full-width
                  heading-text
                  my-7"
    >
      <v-col cols="auto" class="d-flex align-center flex-wrap">
        <h2 class="font-24px mb-1 mr-6">
          <span class="fw-500">{{ items.title }}</span>
          <span class="line" v-if="items.subTitle"></span>
          <span class="font-20px fw-400" v-if="items.subTitle">{{
            items.subTitle
          }}</span>
          <span class="ml-2 font-18px" v-if="items.count"
            >({{ items.count }})</span
          >
        </h2>
      </v-col>
      <v-col
        ref="tabParent"
        class=" d-flex justify-start align-center parent tab-items-parent"
      >
        <template v-if="items.tabs && items.tabs.length > 0">
          <v-btn
            v-for="(tab, index) in items.tabs"
            :key="index"
            :ref="`tab-${index}`"
            :id="`tab-${index}`"
            depressed
            color="transparent"
            @click="tabAction(tab, index)"
            class=" pa-0 mr-4"
          >
            {{ tab.title }} ({{ tab.count }})
            <div class="position-relative" v-if="tab.notification">
              <v-badge
                color="#E14D56"
                inline
                :content="tab.notification"
              ></v-badge>
            </div>
          </v-btn>
        </template>
      </v-col>
      <v-col
        cols="auto"
        class="d-flex font-14px  justify-end align-center"
        :class="items.tabs && items.tabs.length > 5 ? 'full-width mt-5' : ''"
        v-if="items.buttons && items.buttons.length > 0"
      >
        <v-btn
          color="primary"
          min-width="188px"
          min-height="35px"
          v-for="(button, index) in items.buttons"
          :key="index"
          class="ml-5"
          id="btns"
          :class="button.class"
          @click="button.action"
          v-bind="button.others"
        >
          <div class="d-flex align-center">
            <v-icon size="15">{{ button.icon }}</v-icon>
            <span class="ml-1" v-if="button.icon">{{ button.title }}</span>
            <span v-else>{{ button.title }}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col
        class="d-flex font-14px align-center justify-end"
        v-if="items.back"
      >
        <v-btn @click="items.back.action" text color="transparent">
          <v-icon>$ChevronLeft</v-icon>
          <span class="text-7d mt-1">戻る</span></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'pageTitle',
  props: {
    items: {
      default: [],
      required: true
    }
  },
  data() {
    return {
      edgeReached: false,
      lastItemObserver: null,
      showScroll: false,
      selectedOne: null
    }
  },
  mounted() {
    if (this.items.tabs && this.items.tabs.length > 0) {
      const alreadySelectedTab = this.items.tabs.find(tab => {
        return tab.selected === true
      })
      if (alreadySelectedTab) {
        const index = this.items.tabs.findIndex(
          tab => tab.title === alreadySelectedTab.title
        )
        this.$refs[`tab-${index}`][0].$el.classList.add('primary--text')
      } else {
        this.$refs['tab-0'][0].$el.classList.add('primary--text')
      }
    }
  },
  methods: {
    tabAction(tab, index) {
      tab.action()
      for (let refs in this.$refs) {
        if (refs === `tab-${index}`) {
          this.selectedOne = `tab-${index}`
          this.$refs[refs][0]?.$el.classList.add('primary--text')
        } else {
          this.$refs[refs][0]?.$el.classList.remove('primary--text')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#btns {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.tab-items-parent {
}
</style>
