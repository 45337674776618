export const FAQS_REQUEST = 'FAQS_REQUEST'
export const FAQS_GET_ALL = 'FAQS_GET_ALL'
export const FAQS_GET_ALL_SUCCESS = 'FAQS_GET_ALL_SUCCESS'

export const FAQS_GET = 'FAQS_GET'
export const FAQS_GET_SUCCESS = 'FAQS_GET_SUCCESS'

export const FAQS_CREATE = 'FAQS_CREATE'
export const FAQS_CREATE_SUCCESS = 'FAQS_CREATE_SUCCESS'

export const FAQS_UPDATE = 'FAQS_UPDATE'
export const FAQS_UPDATE_SUCCESS = 'FAQS_UPDATE_SUCCESS'

export const FAQS_DELETE = 'FAQS_DELETE'
export const FAQS_DELETE_SUCCESS = 'FAQS_DELETE_SUCCESS'

export const FAQS_CATEGORIES = 'FAQS_CATEGORIES'
export const FAQS_CATEGORIES_SUCCESS = 'FAQS_CATEGORIES_SUCCESS'
