<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99926 12.3359C8.49604 12.3359 8.07422 12.7578 8.07422 13.261C8.07422 13.7642 8.49604 14.186 8.99926 14.186C9.48398 14.186 9.9243 13.7642 9.9021 13.2832C9.9243 12.7541 9.50618 12.3359 8.99926 12.3359Z"
      fill="#3979D9"
    />
    <path
      d="M17.562 15.6141C18.1429 14.6114 18.1466 13.4162 17.5694 12.4172L11.7749 2.38228C11.2014 1.37213 10.1653 0.772705 9.00348 0.772705C7.84162 0.772705 6.80557 1.37583 6.23204 2.37858L0.430163 12.4246C-0.147064 13.4347 -0.143364 14.6373 0.441264 15.64C1.01849 16.6317 2.05084 17.2274 3.2053 17.2274H14.7795C15.9376 17.2274 16.9774 16.6243 17.562 15.6141ZM16.3039 14.8889C15.982 15.4439 15.4122 15.7732 14.7758 15.7732H3.2016C2.57257 15.7732 2.00644 15.4513 1.69192 14.9074C1.37371 14.3561 1.37001 13.6974 1.68822 13.1424L7.4901 3.10012C7.80462 2.54879 8.36705 2.22317 9.00348 2.22317C9.63621 2.22317 10.2023 2.55249 10.5168 3.10382L16.315 13.1461C16.6258 13.6863 16.6221 14.3376 16.3039 14.8889Z"
      fill="#3979D9"
    />
    <path
      d="M8.77077 5.84179C8.33045 5.9676 8.05664 6.36721 8.05664 6.85194C8.07884 7.14425 8.09734 7.44027 8.11954 7.73258C8.18245 8.84633 8.24535 9.93789 8.30825 11.0516C8.33045 11.4291 8.62277 11.7029 9.00019 11.7029C9.3776 11.7029 9.67362 11.4106 9.69212 11.0294C9.69212 10.8 9.69212 10.5891 9.71432 10.356C9.75502 9.64187 9.79942 8.92774 9.84012 8.2136C9.86233 7.75108 9.90303 7.28856 9.92523 6.82604C9.92523 6.65953 9.90303 6.51152 9.84012 6.36351C9.65142 5.94909 9.21109 5.73818 8.77077 5.84179Z"
      fill="#3979D9"
    />
  </svg>
</template>

<script>
export default {
  name: 'Warning'
}
</script>

<style scoped></style>
