export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_GET_ALL = 'USERS_GET_ALL'
export const USERS_GET_ALL_SUCCESS = 'USERS_GET_ALL_SUCCESS'

export const USERS_GET = 'USERS_GET'
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS'

export const USERS_DELETE = 'USERS_DELETE'
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS'

export const USERS_CHANGE_STATUS = 'USERS_CHANGE_STATUS'
export const USERS_CHANGE_STATUS_SUCCESS = 'USERS_CHANGE_STATUS_SUCCESS'

export const USERS_JOBS = 'USERS_JOBS'
export const USERS_JOBS_SUCCESS = 'USERS_JOBS_SUCCESS'
