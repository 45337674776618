<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
    v-if="DRAWER_STATE"
  >
    <v-treeview
      :items="items"
      activatable
      :multiple-active="false"
      selected-color="indigo"
      open-on-click
      expand-icon="mdi-chevron-down"
      on-icon="mdi-bookmark"
      off-icon="mdi-bookmark-outline"
      indeterminate-icon="mdi-bookmark-minus"
      item-text="title"
      item-key="title"
      class="treeview-custom-sidebar"
      return-object
      transition
      v-if="DRAWER_STATE"
    >
      <template v-slot:label="{ item }">
        <div class="d-flex align-center" :class="item.class ? item.class : ''">
          <template v-if="item.link == '/logout'">
            <span class="page-link" @click="logOut()">
              {{ item.title }}
            </span>
          </template>
          <template v-else>
            <router-link
              class="page-link"
              v-if="item.link"
              :to="item.link"
              :class="{
                'text-primary':
                  item.link.name === $route.name ||
                  String($route.name).includes(item.link.highlightOn)
              }"
            >
              {{ item.title }}
            </router-link>
            <span
              class="page-link"
              v-else
              :class="checkChildrens(item.children)"
            >
              {{ item.title }}
            </span>
          </template>
          <template v-if="item.badgeCount">
            <v-badge color="#E14D56" inline :content="item.badgeCount">
            </v-badge>
          </template>
        </div>
      </template>
      <template v-slot:prepend="{ item }">
        <v-icon
          v-if="!item.hideIcon"
          class="icon-arrow-right fill-primary"
          :class="{
            'active-svg':
              (item.link && item.link.name === $route.name) ||
              (item.link && $route.name.includes(item.link.highlightOn)) ||
              (item.children && isChildren(item.children)),
            'active-stroke':
              (item.iconStroke &&
                item.link &&
                item.link.name === $route.name) ||
              (item.iconStroke &&
                item.link &&
                $route.name.includes(item.link.highlightOn)) ||
              (item.children && isChildren(item.children)),
            'child-icon': item.iconStroke
          }"
          :size="item.iconText ? '6' : '17'"
          ref="icon"
          :style="
            item.icon_color
              ? `--color: ${item.icon_color}; --light-color: ${item.icon_color_light}`
              : ''
          "
        >
          {{ item.iconText ? item.iconText : item.icon }}
        </v-icon>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  props: {
    source: String
  },
  data() {
    return {
      sidebarWidth: 240,
      sidebarMinWidth: 76
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'getApprovedCompanies',
      'getTotalStudents',
      'getTotalUnreadApplications',
      'getNewStudentCount'
    ]),
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get() {
        return this.drawer ?? true
      },
      set(newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    },
    items() {
      return [
        {
          title: this.$t('sidebar.link_titles.top'),
          icon: '$HomeIcon',
          link: { name: 'Dashboard' }
        },
        {
          title: this.$t('sidebar.link_titles.job_info.title'),
          icon: '$Search',
          model: false,
          class: 'hide-count-on-open',
          badgeCount: 0,
          iconStroke: true,
          children: [
            {
              title: this.$t('sidebar.link_titles.job_info.list_all', {
                count: this.getMasterData?.total_internships ?? 0
              }),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: {
                name: 'InternshipPostList',
                highlightOn: 'InternshipPostEdit'
              },
              iconStroke: true
            },
            {
              title: this.$t('sidebar.link_titles.job_info.create_new'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              badgeCount: 0,
              iconStroke: true,
              link: { name: 'InternshipPostCreate' }
            },
            {
              title: this.$t('sidebar.link_titles.job_info.draft', {
                count: this.getMasterData?.total_draft_internships ?? 0
              }),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'InternshipPostDraftList' },
              iconStroke: true
            },
            {
              title: '職種管理',
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'WorkCategories' },
              iconStroke: true
            },
            {
              title: this.$t('sidebar.link_titles.job_info.feature_management'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'InternshipFeatures' },
              iconStroke: true
            }
          ]
        },

        {
          title: this.$t('sidebar.link_titles.application.title'),
          icon: '$PaperPlaneIcon',
          model: false,
          badgeCount: this.$store.getters.getTotalUnreadApplications,
          class: 'hide-count-on-open',
          link: { name: 'Applications' }
        },

        {
          title: this.$t('sidebar.link_titles.feedback.title'),
          icon: '$messages',
          model: false,
          children: [
            {
              title: this.$t('sidebar.link_titles.feedback.list_all'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'Feedbacks', highlightOn: 'FeedbackEdit' },
              iconStroke: true
            },
            {
              title: this.$t('sidebar.link_titles.feedback.create_new'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'FeedbackCreate' },
              iconStroke: true
            }
          ]
        },
        {
          title: this.$t('sidebar.link_titles.user_management.title', {
            count: this.$store.getters.getTotalStudents
          }),
          badgeCount: this.$store.getters.getNewStudentCount,
          icon: '$Account',
          link: { name: 'Students', highlightOn: 'StudentProfile' }
        },
        {
          title: this.$t('sidebar.link_titles.corporate.title', {
            count: this.$store.getters.getApprovedCompanies
          }),
          icon: '$Companies',
          model: false,
          children: [
            {
              title: this.$t('sidebar.link_titles.corporate.list_all'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'Corporate', highlightOn: 'CorporateEdit' },
              iconStroke: true
            },
            {
              title: this.$t(
                'sidebar.link_titles.corporate.create_new_corporation'
              ),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'CorporateCreate' },
              iconStroke: true
            }
          ]
        },
        {
          title: this.$t('sidebar.link_titles.column.title'),
          icon: '$Bell',
          model: false,
          children: [
            {
              title: this.$t('sidebar.link_titles.column.list_all'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'Media', highlightOn: 'EditMediaPost' },
              iconStroke: true
            },
            {
              title: this.$t('sidebar.link_titles.column.tag_management'),
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'MediaTags' },
              iconStroke: true
            }
          ]
        },
        {
          title: this.$t('sidebar.link_titles.settings.title'),
          icon: '$Setting',
          model: false,
          children: [
            {
              title: '教育機関',
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'educational_facilities' },
              iconStroke: true
            },
            {
              title: '管理者',
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: { name: 'AdminListing', highlightOn: 'AdminsEdit' },
              iconStroke: true
            },
            {
              title: 'ログアウト',
              icon: 'mdi-circle-small',
              iconText: '$ArrowRightDrop',
              link: '/logout',
              iconStroke: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    logOut() {
      this.$store.dispatch('AUTH_LOGOUT')
    },
    checkChildrens(childrens) {
      var isChildActive = false
      childrens.forEach(children => {
        if (
          children.link.name === this.$route.name ||
          (children.link.highlightOn &&
            this.$route.name.includes(children.link.highlightOn))
        ) {
          isChildActive = true
        }
      })
      return {
        'text-primary active-svg': isChildActive
      }
    },
    isChildren(childrens) {
      var isChildActive = false
      childrens.forEach(children => {
        if (
          children.link.name === this.$route.name ||
          (children.link.highlightOn &&
            this.$route.name.includes(children.link.highlightOn))
        ) {
          isChildActive = true
        }
      })
      return isChildActive
    }
  }
}
</script>

<style src="./Sidebar.scss" lang="scss" />
