<template>
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.1875 0C9.91211 0 10.5 0.587617 10.5 1.3125V12.6875C10.5 13.4121 9.91211 14 9.1875 14H6.5625V11.8125C6.5625 11.0879 5.97461 10.5 5.25 10.5C4.52539 10.5 3.9375 11.0879 3.9375 11.8125V14H1.3125C0.587617 14 0 13.4121 0 12.6875V1.3125C0 0.587617 0.587617 0 1.3125 0H9.1875ZM1.75 7.4375C1.75 7.67812 1.94578 7.875 2.1875 7.875H3.0625C3.30312 7.875 3.5 7.67812 3.5 7.4375V6.5625C3.5 6.32188 3.30312 6.125 3.0625 6.125H2.1875C1.94578 6.125 1.75 6.32188 1.75 6.5625V7.4375ZM4.8125 6.125C4.57188 6.125 4.375 6.32188 4.375 6.5625V7.4375C4.375 7.67812 4.57188 7.875 4.8125 7.875H5.6875C5.92812 7.875 6.125 7.67812 6.125 7.4375V6.5625C6.125 6.32188 5.92812 6.125 5.6875 6.125H4.8125ZM7 7.4375C7 7.67812 7.19688 7.875 7.4375 7.875H8.3125C8.55313 7.875 8.75 7.67812 8.75 7.4375V6.5625C8.75 6.32188 8.55313 6.125 8.3125 6.125H7.4375C7.19688 6.125 7 6.32188 7 6.5625V7.4375ZM2.1875 2.625C1.94578 2.625 1.75 2.82188 1.75 3.0625V3.9375C1.75 4.17812 1.94578 4.375 2.1875 4.375H3.0625C3.30312 4.375 3.5 4.17812 3.5 3.9375V3.0625C3.5 2.82188 3.30312 2.625 3.0625 2.625H2.1875ZM4.375 3.9375C4.375 4.17812 4.57188 4.375 4.8125 4.375H5.6875C5.92812 4.375 6.125 4.17812 6.125 3.9375V3.0625C6.125 2.82188 5.92812 2.625 5.6875 2.625H4.8125C4.57188 2.625 4.375 2.82188 4.375 3.0625V3.9375ZM7.4375 2.625C7.19688 2.625 7 2.82188 7 3.0625V3.9375C7 4.17812 7.19688 4.375 7.4375 4.375H8.3125C8.55313 4.375 8.75 4.17812 8.75 3.9375V3.0625C8.75 2.82188 8.55313 2.625 8.3125 2.625H7.4375Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'Companies'
}
</script>

<style scoped></style>
