<template>
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9374 8.69132C23.7803 8.20535 23.3493 7.86019 22.8393 7.81423L15.9123 7.18524L13.1731 0.774007C12.9712 0.304146 12.5112 0 12.0001 0C11.4891 0 11.0291 0.304146 10.8271 0.775105L8.08797 7.18524L1.15982 7.81423C0.65077 7.86129 0.220828 8.20535 0.0628038 8.69132C-0.0952203 9.1773 0.0507185 9.71033 0.435799 10.0463L5.67183 14.6384L4.12785 21.4396C4.01487 21.9397 4.20897 22.4566 4.62389 22.7566C4.84692 22.9177 5.10785 22.9997 5.37098 22.9997C5.59786 22.9997 5.8229 22.9386 6.02487 22.8177L12.0001 19.2465L17.9732 22.8177C18.4103 23.0807 18.9612 23.0567 19.3752 22.7566C19.7904 22.4557 19.9843 21.9386 19.8713 21.4396L18.3273 14.6384L23.5633 10.0473C23.9484 9.71033 24.0955 9.17821 23.9374 8.69132Z"
      fill="#FFF7C0"
    />
    <path
      d="M5.37171 22.9997C5.10876 22.9997 4.84765 22.9177 4.6248 22.7566C4.20969 22.4557 4.01578 21.9386 4.12876 21.4396L5.67274 14.6384L0.436525 10.0463C0.0516272 9.71033 -0.0954103 9.17821 0.0626139 8.69132C0.220638 8.20535 0.650581 7.86129 1.15963 7.81423L8.08778 7.18524L10.8269 0.775105C11.0289 0.304146 11.4889 0 11.9999 0C12.511 0 12.971 0.304146 13.1729 0.774007L15.9121 7.18524L22.8391 7.81423C23.3493 7.86019 23.7792 8.20535 23.9373 8.69132C24.0953 9.1773 23.9492 9.71033 23.5643 10.0463L18.328 14.6375L19.872 21.4385C19.9852 21.9386 19.7911 22.4557 19.3762 22.7556C18.9621 23.0556 18.4101 23.0787 17.9741 22.8166L11.9999 19.2465L6.02578 22.8186C5.82381 22.9386 5.59877 22.9997 5.37171 22.9997ZM11.9999 17.7086C12.227 17.7086 12.4518 17.7696 12.654 17.8895L18.2921 21.2617L16.835 14.8424C16.731 14.3855 16.886 13.9085 17.2391 13.5994L22.1832 9.26336L15.642 8.66935C15.171 8.62632 14.766 8.33023 14.582 7.89626L11.9999 1.84703L9.41478 7.89718C9.23277 8.32822 8.82773 8.62431 8.35787 8.66734L1.81571 9.26135L6.75969 13.5974C7.11382 13.9074 7.26873 14.3835 7.16381 14.8414L5.70772 21.2606L11.3459 17.8895C11.5478 17.7696 11.7729 17.7086 11.9999 17.7086ZM8.03486 7.30921L8.03377 7.31122L8.03486 7.30921ZM15.963 7.30628L15.9641 7.30829L15.963 7.30628Z"
      fill="#B8B8B8"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarFilled'
}
</script>

<style scoped></style>
