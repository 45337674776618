<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3464 12.9974L2.83854 11.5392C1.67225 11.3737 1 10.2902 1 9.2663V9.26441L1.01131 3.28979C1.01131 3.28943 1.01131 3.28908 1.01131 3.28872L1.01131 3.28789L2.01131 3.28979C2.01131 2.36933 2.8367 1.73408 3.58294 2.11004L12.3464 12.9974ZM12.3464 12.9974L2.83287 14.4487L2.83287 14.4487L2.82519 14.4499C1.67759 14.6341 1 15.7 0.999998 16.7337L1 16.7356L1.01131 22.7102H2.01131L1.01131 22.7121L1.01131 22.7113C1.01163 23.4711 1.35428 24.16 1.91362 24.5835C2.48501 25.0161 3.28187 25.1589 4.02771 24.7856L23.7542 15.0902L23.7571 15.0888C24.6297 14.6564 25 13.7726 25 13C25 12.2274 24.6297 11.3436 23.7571 10.9112L23.7542 10.9098L4.02777 1.21442L12.3464 12.9974Z"
      stroke="#AA158B"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'OpenPaperPlaneIcon'
}
</script>

<style lang="scss" scoped></style>
