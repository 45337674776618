<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C3.81295 0 0 3.81295 0 8.5C0 13.1871 3.81295 17 8.5 17C13.1871 17 17 13.1871 17 8.5C17 3.81295 13.1871 0 8.5 0ZM10.4174 11.5409C10.6944 11.8178 10.6944 12.2655 10.4174 12.5424C10.2793 12.6806 10.098 12.75 9.91664 12.75C9.73532 12.75 9.55397 12.6806 9.41584 12.5424L5.8742 9.0008C5.59725 8.72386 5.59725 8.27618 5.8742 7.99923L9.41588 4.45755C9.69282 4.18061 10.1405 4.18061 10.4174 4.45755C10.6944 4.7345 10.6944 5.18218 10.4174 5.45913L7.37657 8.5L10.4174 11.5409Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PreviousAngleCircle'
}
</script>

<style scoped></style>
