<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.18918 2.61738C5.31528 2.25316 5.4237 1.91824 5.54638 1.58835C5.9074 0.616541 6.76937 0.00755503 7.7972 0.00374008C9.11718 -0.00127959 10.4373 -0.0012797 11.7577 0.00394076C12.8902 0.00815728 13.7315 0.612727 14.1099 1.68031C14.4434 2.6208 14.4434 2.61939 15.4213 2.61578C16.0337 2.61337 16.6465 2.60795 17.2585 2.61899C18.5552 2.64248 19.6031 3.66529 19.6087 4.96057C19.6229 8.18641 19.6221 11.4121 19.6097 14.6377C19.6047 15.9506 18.5521 16.9917 17.2324 16.9935C12.2782 17.002 7.32395 17.0026 2.36993 16.9929C1.05639 16.9899 0.0104881 15.935 0.00667316 14.6218C-0.00256303 11.4098 -0.00256291 8.19765 0.00888194 4.98546C0.0135 3.6691 1.059 2.63565 2.37596 2.61919C3.30098 2.60795 4.22581 2.61738 5.18918 2.61738ZM9.80708 15.6874C12.2035 15.6874 14.5995 15.6894 16.9958 15.6862C17.9024 15.685 18.302 15.2941 18.303 14.4034C18.3066 11.3129 18.3068 8.22235 18.3044 5.13164C18.3036 4.35479 17.8691 3.92872 17.0838 3.92651C16.0764 3.9237 15.0687 3.9239 14.0613 3.92912C13.671 3.93133 13.4196 3.76508 13.2992 3.387C13.1588 2.94687 13.011 2.50856 12.8508 2.07546C12.657 1.55181 12.3223 1.31528 11.7686 1.31327C10.4614 1.30785 9.15432 1.30785 7.8474 1.31327C7.29744 1.31568 6.95269 1.56064 6.76456 2.07626C6.61136 2.49751 6.46578 2.92218 6.33487 3.35106C6.20858 3.76428 5.94294 3.93475 5.51546 3.92932C4.54907 3.91728 3.58208 3.92491 2.6157 3.92591C1.71999 3.92671 1.3148 4.32668 1.3144 5.21316C1.31359 8.27636 1.31339 11.3398 1.3146 14.4032C1.3148 15.2758 1.71858 15.6852 2.57795 15.6858C4.98759 15.687 7.39744 15.6864 9.80708 15.6874Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.90433 9.49264C4.91437 6.7521 7.10475 4.57356 9.8477 4.57617C12.5324 4.57858 14.7268 6.81414 14.711 9.53099C14.6953 12.2115 12.4866 14.3892 9.78988 14.3828C7.10315 14.3766 4.89429 12.1653 4.90433 9.49264ZM13.4018 9.48079C13.399 7.50304 11.7923 5.89173 9.81959 5.88711C7.8302 5.8831 6.20643 7.51148 6.21547 9.50168C6.2241 11.4722 7.84124 13.0753 9.81939 13.0735C11.7887 13.0723 13.4044 11.4515 13.4018 9.48079Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3305 5.23367C16.684 5.22343 16.9828 5.50373 16.9977 5.85952C17.0121 6.2099 16.7314 6.51851 16.3805 6.53778C16.012 6.55786 15.694 6.25909 15.6934 5.89125C15.6926 5.53485 15.9745 5.24411 16.3305 5.23367Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'Camera'
}
</script>

<style scoped></style>
