<template>
  <svg
    viewBox="0 0 27 25"
    fill="var(--light-color)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="13.5"
      cy="11.3359"
      rx="12.5"
      ry="10.5"
      fill="var(--light-color)"
    />
    <path
      d="M5.23858 23.0979L8.58806 18.2213L10.734 20.9069L5.23858 23.0979Z"
      fill="var(--light-color)"
    />
    <path
      d="M13.5 0C6.16945 0 0 5.03176 0 11.5C0 15.0918 1.93757 18.4257 5.23521 20.5967L3.0731 23.1912C2.51227 23.8642 3.15441 24.8726 4.00713 24.6341L10.7177 22.7555C18.9687 24.2256 27 18.9311 27 11.5C27 5.0306 20.8292 0 13.5 0ZM10.8465 20.9488C10.7094 20.9224 10.5681 20.9282 10.4336 20.9658L6.30824 22.1206L7.28847 20.9444C7.65007 20.5104 7.52678 19.8534 7.03202 19.5803C3.75595 17.7719 1.80004 14.7512 1.80004 11.5C1.80004 6.15136 7.04864 1.79998 13.5001 1.79998C19.9515 1.79998 25.2001 6.15136 25.2001 11.5C25.2 17.7735 18.1278 22.3514 10.8465 20.9488Z"
      fill="var(--color)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Chat'
}
</script>

<style scoped></style>
