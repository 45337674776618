<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="width:20px; height:20px;"
    width="20"
    height="20"
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M2.24264 2.24173C-0.0968453 4.58122 -0.0968453 8.38753 2.24264 10.727C4.58213 13.0665 8.38844 13.0665 10.7279 10.727C13.0674 8.38753 13.0674 4.58122 10.7279 2.24173C8.38844 -0.0977515 4.58213 -0.0977515 2.24264 2.24173ZM8.69497 4.98175L7.19234 6.48438L8.69497 7.987C8.89015 8.18218 8.89015 8.49888 8.69497 8.69406C8.49978 8.88925 8.18309 8.88925 7.9879 8.69406L6.48528 7.19144L4.98266 8.69406C4.78747 8.88925 4.47078 8.88925 4.27559 8.69406C4.08041 8.49888 4.08041 8.18218 4.27559 7.987L5.77822 6.48438L4.27559 4.98175C4.08041 4.78657 4.08041 4.46987 4.27559 4.27469C4.47078 4.0795 4.78747 4.0795 4.98266 4.27469L6.48528 5.77731L7.9879 4.27469C8.18309 4.0795 8.49978 4.0795 8.69497 4.27469C8.89015 4.46987 8.89015 4.78657 8.69497 4.98175Z"
      fill="#13ABA3"
    />
  </svg>
</template>

<script>
export default {
  name: 'GreenCross'
}
</script>

<style></style>
