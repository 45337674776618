<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.24264 4.75736C1.90315 7.09685 1.90315 10.9032 4.24264 13.2426C6.58213 15.5821 10.3884 15.5821 12.7279 13.2426C15.0674 10.9032 15.0674 7.09685 12.7279 4.75736C10.3884 2.41787 6.58213 2.41787 4.24264 4.75736ZM10.695 7.49738L9.19234 9L10.695 10.5026C10.8902 10.6978 10.8902 11.0145 10.695 11.2097C10.4998 11.4049 10.1831 11.4049 9.9879 11.2097L8.48528 9.70706L6.98266 11.2097C6.78747 11.4049 6.47078 11.4049 6.27559 11.2097C6.08041 11.0145 6.08041 10.6978 6.27559 10.5026L7.77822 9L6.27559 7.49738C6.08041 7.30219 6.08041 6.9855 6.27559 6.79031C6.47078 6.59513 6.78747 6.59513 6.98266 6.79031L8.48528 8.29294L9.9879 6.79031C10.1831 6.59513 10.4998 6.59513 10.695 6.79031C10.8902 6.9855 10.8902 7.30219 10.695 7.49738Z"
      fill="#FF7283"
    />
  </svg>
</template>

<script>
export default {
  name: 'CrossIcon'
}
</script>

<style scoped></style>
