export const ADMINS_REQUEST = 'ADMINS_REQUEST'
export const ADMINS_GET_ALL = 'ADMINS_GET_ALL'
export const ADMINS_GET_ALL_SUCCESS = 'ADMINS_GET_ALL_SUCCESS'

export const ADMINS_GET = 'ADMINS_GET'
export const ADMINS_GET_SUCCESS = 'ADMINS_GET_SUCCESS'

export const ADMINS_UPDATE = 'ADMINS_UPDATE'
export const ADMINS_UPDATE_SUCCESS = 'ADMINS_UPDATE_SUCCESS'

export const ADMINS_CREATE = 'ADMINS_CREATE'
export const ADMINS_CREATE_SUCCESS = 'ADMINS_CREATE_SUCCESS'

export const ADMINS_DELETE = 'ADMINS_DELETE'
export const ADMINS_DELETE_SUCCESS = 'ADMINS_DELETE_SUCCESS'
