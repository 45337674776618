<template>
  <svg
    width="28"
    height="19"
    viewBox="0 0 28 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C7.63636 0 2.20182 3.93933 0 9.5C2.20182 15.0607 7.63636 19 14 19C20.3636 19 25.7982 15.0607 28 9.5C25.7982 3.93933 20.3636 0 14 0ZM14 15.8333C10.4873 15.8333 7.63636 12.996 7.63636 9.5C7.63636 6.004 10.4873 3.16667 14 3.16667C17.5127 3.16667 20.3636 6.004 20.3636 9.5C20.3636 12.996 17.5127 15.8333 14 15.8333ZM14 5.7C11.8873 5.7 10.1818 7.39733 10.1818 9.5C10.1818 11.6027 11.8873 13.3 14 13.3C16.1127 13.3 17.8182 11.6027 17.8182 9.5C17.8182 7.39733 16.1127 5.7 14 5.7Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeFillIcon'
}
</script>

<style lang="scss" scoped></style>
