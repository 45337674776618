<template>
  <svg
    width="23"
    height="15"
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4987 2.95044C8.99016 2.95044 6.94922 4.99138 6.94922 7.49991C6.94922 10.0084 8.99016 12.0494 11.4987 12.0494C14.0072 12.0494 16.0482 10.0084 16.0482 7.49991C16.0482 4.99138 14.0072 2.95044 11.4987 2.95044ZM11.4987 10.5329C9.82624 10.5329 8.46574 9.17231 8.46574 7.49991C8.46574 5.82751 9.82629 4.46696 11.4987 4.46696C13.1711 4.46696 14.5316 5.82751 14.5316 7.49991C14.5316 9.17231 13.1711 10.5329 11.4987 10.5329Z"
      fill="currentColor"
    />
    <path
      d="M22.8538 7.05272C22.6483 6.77165 17.7526 0.17041 11.4999 0.17041C5.24715 0.17041 0.3512 6.77165 0.145951 7.05246C-0.0486505 7.31911 -0.0486505 7.68078 0.145951 7.94744C0.3512 8.22851 5.24715 14.8298 11.4999 14.8298C17.7526 14.8298 22.6483 8.22847 22.8538 7.94766C23.0487 7.68105 23.0487 7.31911 22.8538 7.05272ZM11.4999 13.3133C6.89408 13.3133 2.90497 8.9319 1.7241 7.49956C2.90344 6.06597 6.8842 1.68689 11.4999 1.68689C16.1055 1.68689 20.0943 6.0675 21.2757 7.5006C20.0963 8.93415 16.1156 13.3133 11.4999 13.3133Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeClose'
}
</script>

<style scoped></style>
