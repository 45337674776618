<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg"
    class="search-icon"
     
  >
    <path
      d="M16.8406 16.0751L12.4515 11.7561C13.6009 10.5074 14.3071 8.85588 14.3071 7.03858C14.3066 3.15103 11.1045 0 7.15425 0C3.20401 0 0.00195312 3.15103 0.00195312 7.03858C0.00195312 10.9261 3.20401 14.0772 7.15425 14.0772C8.86103 14.0772 10.4264 13.4868 11.6561 12.5054L16.0622 16.8414C16.2769 17.0529 16.6255 17.0529 16.8401 16.8414C17.0553 16.6299 17.0553 16.2866 16.8406 16.0751ZM7.15425 12.9942C3.81192 12.9942 1.10244 10.3278 1.10244 7.03858C1.10244 3.74936 3.81192 1.08293 7.15425 1.08293C10.4966 1.08293 13.2061 3.74936 13.2061 7.03858C13.2061 10.3278 10.4966 12.9942 7.15425 12.9942Z"
      class="fill-primary "
    />
  </svg>
</template>

<script>
export default {
  name: 'Search'
}
</script>

<style lang="scss" scoped>
.fill-primary{
  fill: #333333;
  &:hover{
    fill: #13aba3 !important;
  }
}
</style>
