export const MEDIA_TAGS_REQUEST = 'MEDIA_TAGS_REQUEST'
export const MEDIA_TAGS_GET_ALL = 'MEDIA_TAGS_GET_ALL'
export const MEDIA_TAGS_GET_ALL_SUCCESS = 'MEDIA_TAGS_GET_ALL_SUCCESS'
export const MEDIA_TAGS_GET_ALL_ERROR = 'MEDIA_TAGS_GET_ALL_ERROR'

export const MEDIA_TAGS_GET = 'MEDIA_TAGS_GET'
export const MEDIA_TAGS_GET_SUCCESS = 'MEDIA_TAGS_GET_SUCCESS'
export const MEDIA_TAGS_GET_ERROR = 'MEDIA_TAGS_GET_ERROR'

export const MEDIA_TAGS_CREATE = 'MEDIA_TAGS_CREATE'
export const MEDIA_TAGS_CREATE_SUCCESS = 'MEDIA_TAGS_CREATE_SUCCESS'
export const MEDIA_TAGS_CREATE_ERROR = 'MEDIA_TAGS_CREATE_ERROR'
export const MEDIA_TAGS_UPDATE = 'MEDIA_TAGS_UPDATE'
export const MEDIA_TAGS_UPDATE_SUCCESS = 'MEDIA_TAGS_UPDATE_SUCCESS'
export const MEDIA_TAGS_UPDATE_ERROR = 'MEDIA_TAGS_UPDATE_ERROR'
export const MEDIA_TAGS_DELETE = 'MEDIA_TAGS_DELETE'
export const MEDIA_TAGS_DELETE_SUCCESS = 'MEDIA_TAGS_DELETE_SUCCESS'
export const GET_MEDIA_TAGS_CATEGORY_DATA = 'GET_MEDIA_TAGS_CATEGORY_DATA'
export const GET_MEDIA_TAGS_DATA = 'GET_ MEDIA_TAGS_DATA'
export const MEDIA_TAGS_EDIT = 'MEDIA_TAGS_EDIT'
