<template>
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50906 7.86807C5.95188 7.20101 7.21773 4.0309 0.988602 0.861343C-0.125775 1.08352 -1.64529 6.70026 4.93917 8.59151C7.11706 9.36966 6.90625 12.9991 6.90625 14H7.96875C7.96875 14 7.61198 9.86468 9.92527 8.70088C11.4993 7.91018 15.8462 7.65615 17 0.52411C14.8009 -0.872694 7.64933 0.241562 7.82726 7.31665C7.72297 8.31184 7.67111 9.15492 7.16736 8.92477C6.55989 8.64677 6.50906 7.86807 6.50906 7.86807Z"
      fill="#8ADC83"
    />
  </svg>
</template>
<script>
export default {
  name: 'IndepthIcon'
}
</script>
