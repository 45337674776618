var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.DRAWER_STATE)?_c('v-navigation-drawer',{class:{ 'drawer-mini': !_vm.DRAWER_STATE },attrs:{"app":"","clipped":"","mini-variant":!_vm.DRAWER_STATE,"width":_vm.sidebarWidth,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"temporary":_vm.$vuetify.breakpoint.smAndDown,"mini-variant-width":_vm.sidebarMinWidth},model:{value:(_vm.DRAWER_STATE),callback:function ($$v) {_vm.DRAWER_STATE=$$v},expression:"DRAWER_STATE"}},[(_vm.DRAWER_STATE)?_c('v-treeview',{staticClass:"treeview-custom-sidebar",attrs:{"items":_vm.items,"activatable":"","multiple-active":false,"selected-color":"indigo","open-on-click":"","expand-icon":"mdi-chevron-down","on-icon":"mdi-bookmark","off-icon":"mdi-bookmark-outline","indeterminate-icon":"mdi-bookmark-minus","item-text":"title","item-key":"title","return-object":"","transition":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:item.class ? item.class : ''},[(item.link == '/logout')?[_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.logOut()}}},[_vm._v(" "+_vm._s(item.title)+" ")])]:[(item.link)?_c('router-link',{staticClass:"page-link",class:{
              'text-primary':
                item.link.name === _vm.$route.name ||
                String(_vm.$route.name).includes(item.link.highlightOn)
            },attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',{staticClass:"page-link",class:_vm.checkChildrens(item.children)},[_vm._v(" "+_vm._s(item.title)+" ")])],(item.badgeCount)?[_c('v-badge',{attrs:{"color":"#E14D56","inline":"","content":item.badgeCount}})]:_vm._e()],2)]}},{key:"prepend",fn:function(ref){
            var item = ref.item;
return [(!item.hideIcon)?_c('v-icon',{ref:"icon",staticClass:"icon-arrow-right fill-primary",class:{
          'active-svg':
            (item.link && item.link.name === _vm.$route.name) ||
            (item.link && _vm.$route.name.includes(item.link.highlightOn)) ||
            (item.children && _vm.isChildren(item.children)),
          'active-stroke':
            (item.iconStroke &&
              item.link &&
              item.link.name === _vm.$route.name) ||
            (item.iconStroke &&
              item.link &&
              _vm.$route.name.includes(item.link.highlightOn)) ||
            (item.children && _vm.isChildren(item.children)),
          'child-icon': item.iconStroke
        },style:(item.icon_color
            ? ("--color: " + (item.icon_color) + "; --light-color: " + (item.icon_color_light))
            : ''),attrs:{"size":item.iconText ? '6' : '17'}},[_vm._v(" "+_vm._s(item.iconText ? item.iconText : item.icon)+" ")]):_vm._e()]}}],null,false,3709557589)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }