<template>
  <svg
    :width="6"
    :height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.122 4.15848C5.17118 4.12062 5.2 4.06207 5.2 4C5.2 3.93793 5.17118 3.87938 5.122 3.84152L0.621999 0.377417C0.561613 0.330931 0.480056 0.322803 0.411682 0.356455C0.343308 0.390107 0.300001 0.459691 0.300001 0.535898L0.3 7.4641C0.3 7.54031 0.343308 7.60989 0.411682 7.64354C0.480056 7.6772 0.561612 7.66907 0.621999 7.62258L5.122 4.15848Z"
      fill="#333"
      stroke="#333"
      stroke-width="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightDrop'
}
</script>

<style scoped></style>
