<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.14964 0.0444527C0.702072 0.130524 0.314752 0.436077 0.121092 0.849218C0.0264135 1.05149 0.00489569 1.15477 0.00489569 1.4388C0.00489569 2.10586 -0.287746 1.77448 4.63122 6.68915L8.97781 11.0357L4.59249 15.4254C1.03345 18.993 0.194252 19.8537 0.112485 20.0302C-0.0338363 20.3357 -0.0381399 20.8866 0.103878 21.1921C0.228681 21.4633 0.504108 21.7473 0.779536 21.8936C1.08939 22.0528 1.5843 22.0744 1.9415 21.9453C2.1911 21.8506 2.34603 21.7043 6.60225 17.4523L11.0005 13.0584L15.3815 17.4308C18.8975 20.9425 19.8013 21.8248 19.9734 21.9065C20.1542 21.9926 20.2531 22.0098 20.5974 22.0098C20.9546 22.0098 21.0321 21.9926 21.2214 21.8936C21.4883 21.7559 21.7766 21.4503 21.9014 21.1792C22.0391 20.8866 22.0305 20.3314 21.8885 20.0302C21.8067 19.8537 20.9675 18.993 17.4085 15.4254L13.0232 11.0357L17.3698 6.68915C22.2887 1.77448 21.9961 2.10586 21.9961 1.4388C21.9961 1.15907 21.9746 1.05149 21.8842 0.857824C21.5356 0.117613 20.662 -0.183636 19.9304 0.177862C19.8185 0.233809 18.2477 1.77018 15.3815 4.63635L11.0005 9.01307L6.62377 4.63635C3.75329 1.77018 2.1825 0.233809 2.0706 0.177862C1.92428 0.104702 1.44659 -0.0157967 1.36482 0.00141716C1.35191 0.00572014 1.25293 0.022934 1.14964 0.0444527Z"
      fill="#8E8E8E"
    />
  </svg>
</template>
<script>
export default {
  name: 'CloseIcon'
}
</script>
