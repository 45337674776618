export const APPLICATION_REQUEST = 'APPLICATION_REQUEST'
export const APPLICATION_GET_ALL = 'APPLICATION_GET_ALL'
export const APPLICATION_GET_ALL_SUCCESS = 'APPLICATION_GET_ALL_SUCCESS'
export const APPLICATION_GET_ALL_ERROR = 'APPLICATION_GET_ALL_ERROR'

export const APPLICATION_GET = 'APPLICATION_GET'
export const APPLICATION_STUDENT_GET = 'APPLICATION_STUDENT_GET'

export const APPLICATION_GET_SUCCESS = 'APPLICATION_GET_SUCCESS'
export const APPLICATION_GET_ERROR = 'APPLICATION_GET_ERROR'

export const APPLICATION_CREATE = 'APPLICATION_CREATE'
export const APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS'
export const APPLICATION_CREATE_ERROR = 'APPLICATION_CREATE_ERROR'

export const APPLICATION_UPDATE = 'APPLICATION_UPDATE'
export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS'
export const APPLICATION_UPDATE_ERROR = 'APPLICATION_UPDATE_ERROR'

export const APPLICATION_DELETE = 'APPLICATION_DELETE'
export const APPLICATION_DELETE_SUCCESS = 'APPLICATION_DELETE_SUCCESS'

export const GET_APPLICATION_CATEGORY_DATA = 'GET_APPLICATION_CATEGORY_DATA'
export const GET_APPLICATION_CATEGORY_SUCCESS = 'GET_APPLICATION_CATEGORY_DATA'

export const UPDATE_APPLICATION_ADMIN_READ = 'UPDATE_APPLICATION_ADMIN_READ'
export const UPDATE_APPLICATION_ADMIN_READ_SUCCESS =
  'UPDATE_APPLICATION_ADMIN_READ_SUCCESS'
