<template>
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2905 8.57213L13.1066 2.26813L9.77956 4.61926L6.81504 0.703125L3.85051 4.61926L0.523438 2.26813L1.3396 8.57213H12.2905Z"
      fill="#F6B60D"
    />
  </svg>
</template>
<script>
export default {
  name: 'LeadershipIcon'
}
</script>
