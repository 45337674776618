<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M9.88086 2.6875L1.07744 11.4916C1.03315 11.536 1.00117 11.5921 0.985939 11.6523L0.0101637 15.5689C-0.0190245 15.6868 0.0156207 15.8122 0.101662 15.8982C0.166765 15.9633 0.255472 15.9994 0.346209 15.9994C0.374001 15.9994 0.402428 15.9959 0.430094 15.989L4.34665 15.0131C4.40769 14.9978 4.46315 14.966 4.50744 14.9217L13.3116 6.11826L9.88086 2.6875Z"
        fill="#B8B8B8"
      />
      <path
        d="M15.4924 1.48706L14.5124 0.507094C13.8574 -0.147864 12.7159 -0.14723 12.0617 0.507094L10.8613 1.70749L14.292 5.13813L15.4924 3.93773C15.8195 3.61069 15.9997 3.17541 15.9997 2.71246C15.9997 2.24951 15.8195 1.81422 15.4924 1.48706Z"
        fill="#B8B8B8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Pincel'
}
</script>

<style scoped></style>
