export const CORPORATE_REQUEST = 'CORPORATE_REQUEST'
export const CORPORATE_GET_ALL = 'CORPORATE_GET_ALL'
export const CORPORATE_GET_ALL_SUCCESS = 'CORPORATE_GET_ALL_SUCCESS'
export const CORPORATE_GET_ALL_ERROR = 'CORPORATE_GET_ALL_ERROR'

export const CORPORATE_GET = 'CORPORATE_GET'
export const CORPORATE_GET_SUCCESS = 'CORPORATE_GET_SUCCESS'
export const CORPORATE_GET_ERROR = 'CORPORATE_GET_ERROR'

export const CORPORATE_CREATE = 'CORPORATE_CREATE'
export const CORPORATE_CREATE_SUCCESS = 'CORPORATE_CREATE_SUCCESS'
export const CORPORATE_CREATE_ERROR = 'CORPORATE_CREATE_ERROR'

export const CORPORATE_UPDATE = 'CORPORATE_UPDATE'
export const CORPORATE_UPDATE_SUCCESS = 'CORPORATE_UPDATE_SUCCESS'
export const CORPORATE_UPDATE_ERROR = 'CORPORATE_UPDATE_ERROR'

export const CORPORATE_DELETE = 'CORPORATE_DELETE'
export const CORPORATE_DELETE_SUCCESS = 'CORPORATE_DELETE_SUCCESS'

export const GET_CORPORATE_CATEGORY_DATA = 'GET_CORPORATE_CATEGORY_DATA'
export const GET_CORPORATE_CATEGORY_SUCCESS = 'GET_CORPORATE_CATEGORY_DATA'
