<template>
  <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9" fill="none">
<path d="M5.88033 4.94555L2.01047 8.81528C1.7643 9.06157 1.36517 9.06157 1.11912 8.81528C0.873053 8.56921 0.873053 8.17011 1.11912 7.92406L4.54336 4.49994L1.11922 1.07593C0.873152 0.829759 0.873152 0.430697 1.11922 0.184627C1.36529 -0.0615424 1.7644 -0.0615424 2.01057 0.184627L5.88043 4.05442C6.00347 4.17752 6.06491 4.33868 6.06491 4.49992C6.06491 4.66123 6.00335 4.82251 5.88033 4.94555Z" fill="#1F2020"/>
</svg>
  </div>
</template>

<script>
export default {
name: 'RightArrow'
}
</script>

<style>

</style>