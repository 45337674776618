export const INTERNSHIP_FEATURES_REQUEST = 'INTERNSHIP_FEATURES_REQUEST'
export const INTERNSHIP_FEATURES_GET_ALL = 'INTERNSHIP_FEATURES_GET_ALL'
export const INTERNSHIP_FEATURES_GET_ALL_SUCCESS =
  'INTERNSHIP_FEATURES_GET_ALL_SUCCESS'
export const INTERNSHIP_FEATURES_GET_ALL_ERROR =
  'INTERNSHIP_FEATURES_GET_ALL_ERROR'

export const INTERNSHIP_FEATURES_GET = 'INTERNSHIP_FEATURES_GET'
export const INTERNSHIP_FEATURES_GET_SUCCESS = 'INTERNSHIP_FEATURES_GET_SUCCESS'
export const INTERNSHIP_FEATURES_GET_ERROR = 'INTERNSHIP_FEATURES_GET_ERROR'

export const INTERNSHIP_FEATURES_CREATE = 'INTERNSHIP_FEATURES_CREATE'
export const INTERNSHIP_FEATURES_CREATE_SUCCESS =
  'INTERNSHIP_FEATURES_CREATE_SUCCESS'
export const INTERNSHIP_FEATURES_CREATE_ERROR =
  'INTERNSHIP_FEATURES_CREATE_ERROR'
export const INTERNSHIP_FEATURES_UPDATE = 'INTERNSHIP_FEATURES_UPDATE'
export const INTERNSHIP_FEATURES_UPDATE_SUCCESS =
  'INTERNSHIP_FEATURES_UPDATE_SUCCESS'
export const INTERNSHIP_FEATURES_UPDATE_ERROR =
  'INTERNSHIP_FEATURES_UPDATE_ERROR'
export const INTERNSHIP_FEATURES_DELETE = 'INTERNSHIP_FEATURES_DELETE'
export const INTERNSHIP_FEATURES_DELETE_SUCCESS =
  'INTERNSHIP_FEATURES_DELETE_SUCCESS'
export const GET_INTERNSHIP_FEATURES_CATEGORY_DATA =
  'GET_ INTERNSHIP_FEATURES_CATEGORY_DATA'
export const INTERNSHIP_FEATURES_DATA =
  'GET_ INTERNSHIP_FEATURES_DATA'
export const INTERNSHIP_FEATURES_EDIT = 'INTERNSHIP_FEATURES_EDIT'
