<template>
  <div>
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.45477V6.20308C0 3.16984 2.36813 0.582681 5.59688 0.0845174C7.69219 -0.244844 9.90938 0.399992 11.4375 1.81681L12 2.33667L12.5203 1.81681C14.0906 0.399992 16.2656 -0.244844 18.4031 0.0845174C21.6328 0.582681 24 3.16984 24 6.20308V6.45477C24 8.25562 23.1938 9.97837 21.7687 11.2064L13.2984 18.527C12.9469 18.8308 12.4828 19 12 19C11.5172 19 11.0531 18.8308 10.7016 18.527L2.23078 11.2064C0.807656 9.97837 1.40625e-05 8.25562 1.40625e-05 6.45477H0Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HeartIcon'
}
</script>
