<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.81579 6.50038C7.6514 6.50038 9.1391 5.04516 9.1391 3.25019C9.1391 1.45522 7.6514 0 5.81579 0C3.98018 0 2.49248 1.45522 2.49248 3.25019C2.49248 5.04516 3.98018 6.50038 5.81579 6.50038ZM7.13213 7.7192H4.49945C2.01502 7.7192 0 9.68963 0 12.1197C0 12.6057 0.402951 13 0.89989 13H10.7322C11.2291 13.0008 11.6316 12.6072 11.6316 12.1197C11.6316 9.68963 9.61682 7.7192 7.13213 7.7192Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'Account'
}
</script>

<style scoped></style>
