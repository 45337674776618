<template>
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4987 4.95044C8.99016 4.95044 6.94922 6.99138 6.94922 9.49991C6.94922 12.0084 8.99016 14.0494 11.4987 14.0494C14.0072 14.0494 16.0482 12.0084 16.0482 9.49991C16.0482 6.99138 14.0072 4.95044 11.4987 4.95044ZM11.4987 12.5329C9.82624 12.5329 8.46574 11.1723 8.46574 9.49991C8.46574 7.82751 9.82629 6.46696 11.4987 6.46696C13.1711 6.46696 14.5316 7.82751 14.5316 9.49991C14.5316 11.1723 13.1711 12.5329 11.4987 12.5329Z"
      fill="currentColor"
    />
    <line
      x1="20"
      y1="1.41421"
      x2="3.41421"
      y2="18"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M22.8538 9.05272C22.6483 8.77165 17.7526 2.17041 11.4999 2.17041C5.24715 2.17041 0.3512 8.77165 0.145951 9.05246C-0.0486505 9.31911 -0.0486505 9.68078 0.145951 9.94744C0.3512 10.2285 5.24715 16.8298 11.4999 16.8298C17.7526 16.8298 22.6483 10.2285 22.8538 9.94766C23.0487 9.68105 23.0487 9.31911 22.8538 9.05272ZM11.4999 15.3133C6.89408 15.3133 2.90497 10.9319 1.7241 9.49956C2.90344 8.06597 6.8842 3.68689 11.4999 3.68689C16.1055 3.68689 20.0943 8.0675 21.2757 9.5006C20.0963 10.9341 16.1156 15.3133 11.4999 15.3133Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeClose'
}
</script>

<style scoped></style>
