export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST'
export const NOTIFICATIONS_GET_ALL = 'NOTIFICATIONS_GET_ALL'
export const NOTIFICATIONS_GET_ALL_SUCCESS = 'NOTIFICATIONS_GET_ALL_SUCCESS'

export const NOTIFICATIONS_GET = 'NOTIFICATIONS_GET'
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS'

export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE'
export const NOTIFICATIONS_UPDATE_SUCCESS = 'NOTIFICATIONS_UPDATE_SUCCESS'

export const NOTIFICATIONS_CREATE = 'NOTIFICATIONS_CREATE'
export const NOTIFICATIONS_CREATE_SUCCESS = 'NOTIFICATIONS_CREATE_SUCCESS'

export const NOTIFICATIONS_DELETE = 'NOTIFICATIONS_DELETE'
export const NOTIFICATIONS_DELETE_SUCCESS = 'NOTIFICATIONS_DELETE_SUCCESS'
