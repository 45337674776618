<template>
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6778 2.3773L4.87485 8.17986C4.44513 8.60966 3.74806 8.60966 3.31794 8.17986L0.322409 5.18411C-0.10747 4.75431 -0.10747 4.05716 0.322409 3.62729C0.75237 3.19732 1.44938 3.19732 1.87915 3.62712L4.09662 5.84462L9.12082 0.820395C9.55078 0.390435 10.2478 0.39076 10.6776 0.820395C11.1074 1.25027 11.1074 1.94718 10.6778 2.3773Z"
      fill="#FF7283"
    />
  </svg>
</template>

<script>
export default {
  name: 'Check'
}
</script>

<style scoped></style>
