<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7337 4.47105C10.5594 1.73896 8.66987 -0.342029 7.71369 0.0468498C6.0901 0.706697 8.68084 3.87586 0.708098 7.11666C0.0194777 7.39653 -0.155087 8.51689 0.133086 9.18668C0.420666 9.85631 1.36275 10.523 2.05167 10.2431C2.17077 10.1948 2.60844 10.054 2.60844 10.054C3.10025 10.714 3.61504 10.3226 3.79762 10.742C4.01697 11.246 4.49425 12.3416 4.65621 12.7143C4.81876 13.0867 5.18658 13.4317 5.45369 13.3303C5.71991 13.2288 6.62685 12.8837 6.9739 12.7517C7.32066 12.6197 7.40342 12.3106 7.29752 12.0666C7.18317 11.8038 6.71435 11.7267 6.58087 11.42C6.44709 11.1133 6.01016 10.1302 5.88454 9.82042C5.71368 9.39906 6.07661 9.05631 6.60386 9.00143C10.2326 8.62323 10.911 10.8644 12.1465 10.3619C13.1025 9.97303 12.9078 7.20328 11.7337 4.47105ZM11.325 8.90325C11.1126 8.98957 9.68448 7.86313 8.77176 5.73913C7.85934 3.61557 7.97398 1.67415 8.18607 1.58798C8.39816 1.50166 9.79112 2.86051 10.7037 4.98406C11.6161 7.10762 11.5374 8.81693 11.325 8.90325Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'Bell'
}
</script>

<style scoped></style>
