export const FACILITIES_REQUEST = 'FACILITIES_REQUEST'
export const FACILITIES_GET_ALL = 'FACILITIES_GET_ALL'
export const FACILITIES_GET_ALL_SUCCESS = 'FACILITIES_GET_ALL_SUCCESS'
export const FACILITIES_GET_ALL_ERROR = 'FACILITIES_GET_ALL_ERROR'

export const FACILITIES_GET = 'FACILITIES_GET'
export const FACILITIES_GET_SUCCESS = 'FACILITIES_GET_SUCCESS'
export const FACILITIES_GET_ERROR = 'FACILITIES_GET_ERROR'

export const FACILITIES_CREATE = 'FACILITIES_CREATE'
export const FACILITIES_CREATE_SUCCESS = 'FACILITIES_CREATE_SUCCESS'
export const FACILITIES_CREATE_ERROR = 'FACILITIES_CREATE_ERROR'

export const FACILITIES_UPDATE = 'FACILITIES_UPDATE'
export const FACILITIES_UPDATE_SUCCESS = 'FACILITIES_UPDATE_SUCCESS'
export const FACILITIES_UPDATE_ERROR = 'FACILITIES_UPDATE_ERROR'

export const FACILITIES_DELETE = 'FACILITIES_DELETE'
export const FACILITIES_DELETE_SUCCESS = 'FACILITIES_DELETE_SUCCESS'

export const GET_FACILITIES_CATEGORY_DATA = 'GET_FACILITIES_CATEGORY_DATA'
export const GET_FACILITIES_CATEGORY_SUCCESS = 'GET_FACILITIES_CATEGORY_DATA'
export const FACILITIES_EDIT = 'FACILITIES_EDIT'
