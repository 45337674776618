<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M15.2729 0H9.4547C9.05304 0 8.72741 0.325625 8.72741 0.727281C8.72741 1.12894 9.05304 1.45456 9.4547 1.45456H13.5171L6.03138 8.94031C5.74735 9.22434 5.74735 9.68478 6.03138 9.96881C6.17335 10.1108 6.35948 10.1818 6.5456 10.1818C6.73173 10.1818 6.91788 10.1108 7.05988 9.96878L14.5456 2.48306V6.54547C14.5456 6.94712 14.8713 7.27275 15.2729 7.27275C15.6746 7.27275 16.0002 6.94712 16.0002 6.54547V0.727281C16.0002 0.325625 15.6745 0 15.2729 0Z"
        fill="#333333"
      />
      <path
        d="M12.3636 7.27232C11.962 7.27232 11.6363 7.59794 11.6363 7.9996V14.5451H1.45453V4.36322H8C8.40166 4.36322 8.72728 4.0376 8.72728 3.63594C8.72728 3.23429 8.40166 2.90869 8 2.90869H0.727281C0.325625 2.90869 0 3.23432 0 3.63597V15.2723C0 15.674 0.325625 15.9996 0.727281 15.9996H12.3637C12.7653 15.9996 13.0909 15.674 13.0909 15.2723V7.9996C13.0909 7.59794 12.7653 7.27232 12.3636 7.27232Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'OpenNewTab'
}
</script>

<style scoped></style>
