<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00732 12.935L13.5629 7.20478C14.1457 6.93666 14.1457 6.06334 13.5629 5.79522L1.00732 0.065024C0.532443 -0.157136 0.00719516 0.218238 0.00719516 0.762147L0 4.29372C0 4.67676 0.266221 5.00617 0.62598 5.05213L10.7928 6.5L0.62598 7.94021C0.266221 7.99383 0 8.32324 0 8.70628L0.00719516 12.2379C0.00719516 12.7818 0.532443 13.1571 1.00732 12.935Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperPlaneIcon'
}
</script>
