<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.30415 3.57727V2.03759L2.39954 3.28351L1.10025 5.07305L0.698322 4.78106L3.55239 0.850913L6.40646 4.78106L6.00453 5.07305L4.7052 3.28351L3.8006 2.03762V3.57727V12.0338H3.30415V3.57727Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M13.1954 9.51914V0.5625H11.6989V9.5192L10.1055 7.32482L8.89453 8.20459L12.4472 13.0963L15.9993 8.2041L14.7883 7.32531L13.1954 9.51914Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'SwapVertical'
}
</script>

<style scoped></style>
