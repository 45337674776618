<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9566 7C11.9566 6.839 11.9496 6.685 11.9357 6.524L13.227 5.537C13.5047 5.327 13.581 4.935 13.4075 4.627L12.1093 2.366C11.9357 2.058 11.5609 1.932 11.2415 2.072L9.74898 2.709C9.49213 2.527 9.22138 2.366 8.93676 2.233L8.73544 0.616C8.69378 0.266 8.39528 0 8.04817 0H5.45877C5.10473 0 4.80622 0.266 4.76456 0.616L4.56324 2.233C4.27862 2.366 4.00787 2.527 3.75102 2.709L2.25847 2.072C1.93913 1.932 1.56426 2.058 1.39071 2.366L0.0925366 4.634C-0.0810156 4.942 -0.00465263 5.327 0.273031 5.544L1.56426 6.531C1.55038 6.685 1.54343 6.839 1.54343 7C1.54343 7.161 1.55038 7.315 1.56426 7.476L0.273031 8.463C-0.00465263 8.673 -0.0810156 9.065 0.0925366 9.373L1.39071 11.634C1.56426 11.942 1.93913 12.068 2.25847 11.928L3.75102 11.291C4.00787 11.473 4.27862 11.634 4.56324 11.767L4.76456 13.384C4.80622 13.734 5.10473 14 5.45183 14H8.04123C8.38833 14 8.68684 13.734 8.7285 13.384L8.92982 11.767C9.21444 11.634 9.48518 11.473 9.74204 11.291L11.2346 11.928C11.5539 12.068 11.9288 11.942 12.1023 11.634L13.4005 9.373C13.5741 9.065 13.4977 8.68 13.22 8.463L11.9288 7.476C11.9496 7.315 11.9566 7.161 11.9566 7ZM6.77777 9.45C5.43795 9.45 4.34804 8.351 4.34804 7C4.34804 5.649 5.43795 4.55 6.77777 4.55C8.11759 4.55 9.2075 5.649 9.2075 7C9.2075 8.351 8.11759 9.45 6.77777 9.45Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'Setting'
}
</script>

<style scoped></style>
