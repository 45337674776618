<template>
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 13C5.77614 13 6 12.7761 6 12.5V8.91176C6 8.63562 6.22386 8.41177 6.5 8.41177H8.5C8.77614 8.41177 9 8.63562 9 8.91177V12.5C9 12.7761 9.22386 13 9.5 13H12.25C12.5261 13 12.75 12.7761 12.75 12.5V7.38235C12.75 7.10621 12.9739 6.88235 13.25 6.88235H13.7156C14.1715 6.88235 14.3896 6.32218 14.0537 6.01396L7.83806 0.310218C7.64684 0.134748 7.35316 0.134748 7.16194 0.310218L0.94633 6.01396C0.610445 6.32218 0.828514 6.88235 1.28439 6.88235H1.75C2.02614 6.88235 2.25 7.10621 2.25 7.38235V12.5C2.25 12.7761 2.47386 13 2.75 13H5.5Z" fill="#333333"/>
</svg>

</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>
<style lang="scss" scoped>

</style>
