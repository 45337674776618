export const STUDENT_CSV = 'STUDENT_CSV'
export const GET_EDUCATION__FACILITY_DATA = 'GET_EDUCATION__FACILITY_DATA'
export const STUDENT_GET_ALL = 'STUDENT_GET_ALL'
export const STUDENT_GET_ALL_SUCCESS = 'STUDENT_GET_ALL_SUCCESS'
export const STUDENT_GET_ALL_ERROR = 'STUDENT_GET_ALL_ERROR'
export const STUDENT_GET = 'STUDENT_GET'
export const STUDENT_GET_SUCCESS = 'STUDENT_GET_SUCCESS'
export const STUDENT_GET_ERROR = 'STUDENT_GET_ERROR'
export const STUDENT_REQUEST = 'STUDENT_REQUEST'
export const GET_STUDENT_FEEDBACK = 'GET_STUDENT_FEEDBACK'
export const GET_STUDENT_FEEDBACK_SUCCESS = 'GET_STUDENT_FEEDBACK_SUCCESS'
export const GET_SINGLE_STUDENT = 'GET_SINGLE_STUDENT'
export const GET_SINGLE_STUDENT_SUCCESS = 'GET_SINGLE_STUDENT_SUCCESS'
export const UPDATE_SINGLE_STUDENT = 'UPDATE_SINGLE_STUDENT'
export const DELETE_STUDENT_RECORD = 'DELETE_STUDENT_RECORD'
